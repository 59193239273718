* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.partners {
  background-image: url("./Assets/img/partner.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 1000px;
  .list {
    .item {
      img {
        width: 100%;
      }
    }
  }
}

.project {
  background-color: #fce19f;
  .hexagon {
    .yellow {
      position: absolute;
      top: 125px;
    }
  }
  .slider {
    width: 100%;
    position: absolute;
    top: 500px;
  }
}

.contact {
  background-color: #ffd016;
  width: 100%;
  min-height: 650px;
  padding-top: 50px;
  #container {
    margin-top: 10px;
  }
}

@media (max-width: 1200px) {
  .contact {
    #container {
      padding-top: 30px;
    }
  }
}
@media (max-width: 992px) {
  .contact {
    #container {
      padding-top: 20px;
    }
  }
}
@media (max-width: 768px) {
  .contact {
    .pic1 {
      left: 2px;
      top: 218px;
      width: 50%;
    }
    .pic2 {
      width: 55%;
      right: 4px;
      bottom: 3px;
    }
  }
  .project {
    .aboutus {
      top: 320px;
      width: 75%;
    }
  }
}
@media (max-width: 600px) {
  .partners {
    min-height: 700px;
  }
  .project {
    min-height: 870px;
    .aboutus {
      h4 {
        font-size: 24px;
      }
      p {
        font-size: 12px;
      }
    }
  }
  .footer {
    img {
      margin-left: 25px;
    }
  }
  .contact {
    #container {
      position: absolute;
      top: 25px;
    }
    .pic1 {
      left: 1px;
      top: 312px;
    }
    .pic2 {
      right: 1px;
      bottom: 1px;
    }
  }
}
@media (max-width: 500px) {
  .partners {
    min-height: 850px;
  }
  .project{
    .aboutus{
      display: none;
    }
  }
  .contact{
    .pic1{
      left: 1px;
      top: 368px;
    }
  }
  .footer{
    img{
      margin-left: 5px;
    }
  }
}
@media (max-width: 400px) {
  .partners {
    min-height: 750px;
    h3 {
      padding-top: 3rem;
    }
    .list{
      .item{
        width: 90%;
      }
    }
  }
  .project{
    .aboutus{
      width: 100%;
    }
  }
  .footer {
    img {
      margin-left: 5px;
      width: 80%;
    }
    p {
      font-size: 12px;
    }
  }
  .contact {
    .pic1 {
      top: 425px;
    }
  }
}
