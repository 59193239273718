@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  .reponsiveWrapper {
    @apply xl:px-[60px] h-full;
  }
  body {
    @apply text-secondary bg-gradient-to-b from-[#F6FAFD] to-[#FFFFFF] bg-no-repeat overflow-x-hidden;
    /* font-family: 'Open Sans', sans-serif; */
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-primary text-primary;
    font-family: 'Open Sans', sans-serif;
  }

  a {
    @apply font-semibold text-secondary hover:text-accent-primary hover:transition;
  }

  a:hover {
    --tw-text-opacity: 1;
    color: #efaf03; 
  }

  .btn-contact {
    @apply rounded-full px-[18px] py-[6px] text-black border-2 border-black hover:border-white hover:bg-[#edae1e] hover:text-white transition;
  }

  .btn-custom {
    @apply rounded-full px-[22px] py-[8px] text-white bg-[#edae1e] transition;
  }

  .btn-custom-hover {
    @apply hover:-translate-y-[2px] hover:shadow-md hover:shadow-[#edae1e] hover:bg-[#ffc133];
  }

  .btn {
    @apply text-lg py-[15px] px-[28px] rounded-full transition text-white;
  }

  .btn-sm {
    @apply text-[1.135rem] py-[9px] px-[18px] rounded-full transition text-black;
  }

  .btn-primary {
    @apply bg-[#f2c45c] text-[black] font-bold shadow-md hover:shadow-lg hover:shadow-[#efaf03];
  }

  .btn-secondary {
    @apply bg-[#f2c45c] text-[black] font-[500] drop-shadow-md shadow-md hover:bg-[#d49300] hover:text-white hover:shadow-[#efaf03];
  }

  .btn-quaternary {
    @apply text-base font-bold bg-white shadow-md text-primary;
  }

  .responsive-scale {
    @apply scale-[.4] xxs:scale-[.5] xs:scale-[.6] sm:scale-75 md:scale-100
  }

  .responsive-title {
    @apply text-[38px] xxs:text-[42px] xs:text-[48px] sm:text-[54px] lg:text-[60px]
           leading-[42px] xxs:leading-[52px] xs:leading-[58px] sm:leading-[72px]
           font-extrabold
           font-['DM_Sans']
  }

  .responsive-subtitle{
    @apply text-[20px] xs:text-[24px] sm:text-[28px]
           font-['DM_Sans']
  }
  
  /* Beetech theme hover efffect */
  .btn-quaternary:hover {
    @apply bg-white text-base font-bold text-primary shadow-lg shadow-[#efaf03];
    background-color: #e0a300;
    color: black;
  }

  .btn-rounded {
    @apply rounded-[25px] p-[8px_16px];
  }

  .h1 {
    @apply text-[40px] xl:text-[80px] font-bold leading-[1.1];
  }

  .h2 {
    @apply text-[40px] font-bold leading-tight;
  }

  .h3 {
    @apply text-[24px];
  }

  .lead {
    @apply text-lg font-medium leading-relaxed text-secondary;
  }

  .selected-hexagon {
    @apply hover:scale-105 hover:cursor-pointer;
  }
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
   url("./Assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
 }

 @font-face {
  font-family: "DM Sans";
  src: local("DM_Sans"),
   url("./Assets/fonts/DM_Sans/DMSans-VariableFont_opsz,wght.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
 }